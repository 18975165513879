

















import { defineComponent } from '@vue/composition-api'
import { useGetters } from '@u3u/vue-hooks'

import Hero from '@/components/Hero.vue'
import RelatedNews from '@/components/RelatedNews.vue'

export default defineComponent({
  name: 'news-single-page',
  components: {
    Hero,
    RelatedNews,
  },
  setup() {
    const { content } = useGetters(['content'])

    const tempHero = {
      // eslint-disable-next-line prettier/prettier
      title:
        'Liège-Berlin en train de nuit avec couchettes: ce sera peut-être pour dans un an',
      picture: {
        src: 'https://via.placeholder.com/1680x700',
        srcset: '',
        alt: 'Mobility Hero Alt',
      },
      readingTime: 'Temps de lecture 2min.',
    }

    return {
      content,
      tempHero,
    }
  },
})
