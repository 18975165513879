

































import { defineComponent, onMounted } from '@vue/composition-api'
import { gsap } from 'gsap'
import CustomEase from 'gsap/dist/CustomEase.js'

export default defineComponent({
  name: 'Hero',
  props: {
    content: Object,
  },
  setup() {
    onMounted(() => {
      const illustrationContainer = document.querySelector(
        '.hero__illustration'
      )
      const pictureContainer = document.querySelector('.hero__picture-outer')
      const heroContent = document.querySelector('.hero__content')

      const tl = gsap.timeline()

      const customEase = CustomEase.create(
        'custom',
        'M0,0 C0.262,0.75 -0.198,0.998 1,1 '
      )

      tl.add('start')
        .set(heroContent, {
          opacity: 0,
          y: 50,
        })
        .set(pictureContainer, {
          opacity: 0,
          y: 50,
          scale: 0.5,
        })
        .set(illustrationContainer, {
          opacity: 0,
          y: 50,
          scale: 0.5,
        })
        .to(
          pictureContainer,
          {
            duration: 1.6,
            opacity: 1,
            y: 0,
            scale: 1,
            transformOrigin: 'center bottom',
            ease: customEase,
          },
          'start'
        )
        .to(
          illustrationContainer,
          {
            duration: 1.6,
            opacity: 1,
            y: 0,
            scale: 1,
            transformOrigin: 'center bottom',
            ease: customEase,
          },
          'start'
        )
        .to(
          heroContent,
          {
            duration: 1.5,
            opacity: 1,
            y: 0,
            ease: 'power4.out',
          },
          '<0.3'
        )
    })
  },
})
